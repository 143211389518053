@font-face {
  font-family: "Montserrat";
  src: local("../../public/fonts/Montserrat-Regular.ttf"),
    url("../../public/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-bd";
  src: local("../../public/fonts/Montserrat-Bold.ttf"),
    url("../../public/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-eb";
  src: local("../../public/fonts/Montserrat-ExtraBold.ttf"),
    url("../../public/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}
