.banner {
  height: 100px;
  display: none;

  @media (max-width: 450px) {
    display: block;
  }
}

.banner__wrapper {
  background: #fff;
  color: #111;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 10px;

  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;

  display: flex;
  align-items: center;
  gap: 20px;

  z-index: 99;

  p {
    font-size: 10px;
  }

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  div {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 10px;
  }

  a {
    padding: 10px;
    background: #111;
    color: #fff;
    border: none;
    border-radius: 10px;
  }

  button {
    width: 30px;
    height: 30px;
    position: relative;
    background: none;
    font-size: 12px;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: #111;
      left: 0;
      transform: rotate(45deg);
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: #111;
      left: 0;
      transform: rotate(-45deg);
    }
  }
}