.header {
  position: relative;
}

.header__wrapper {
  position: absolute;
  padding-top: 20px;

  a {
    img {
      width: 400px;
      height: auto;

      @media (max-width: 454px) {
        width: 100%;
        padding-right: 10px;
      }
    }
  }
}
