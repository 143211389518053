.footer {
  background-image: url("../../assets/images/footer-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 60px;

  @media (max-width: 1560px) {
    background-position: center;
  }

  @media (max-width: 425px) {
    margin-top: 0px;
  }
}

.footer__wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 160px;
  padding-bottom: 40px;

  @media (max-width: 767px) {
    flex-direction: column;
    padding-top: 200px;
  }

  @media (max-width: 365px) {
    padding-top: 250px;
  }
}

.footer__left {
  display: flex;
  flex-direction: column;
  gap: 10px;

  img {
    width: 70%;
    height: auto;
  }

  a {
    font-size: 14px;
    color: var(--color-white);

    &:not(:last-child) {
      text-decoration: underline;
    }
  }

  .footer__top__button {
    margin-top: 10px;

    a {
      background: var(--color-dark-red);
      padding: 8px 60px;
      border-radius: 10px;
      transition: all 0.2s ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.footer__right {
  p {
    font-size: 8px;
    width: 520px;
    color: var(--color-white);

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  a {
    font-size: 12px;
    text-decoration: underline;
    color: var(--color-white);
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    margin-top: 20px;
  }
}
