.head {
  height: 100vh;
  padding-top: 80px;
  border-radius: 0px 0px 60px 60px;
  transform-style: preserve-3d;

  &::before {
    content: "";
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);

    transform: translateX(-1px);
  }
}

.head__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
}

.head__cover {
  background: var(--color-red);
}

.head__left {
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 2;
  padding: 20px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.3);

  width: 50vw;

  @media (max-width: 1190px) {
    width: 100%;
  }

  h1 {
    font-size: 48px;
    font-weight: 800;
    font-family: "Montserrat-eb", sans-serif;
    width: 620px;
    color: var(--color-white);

    span {
      color: var(--color-yellow);
    }

    @media (max-width: 680px) {
      width: 100%;
      font-size: 32px;
    }
  }

  a {
    display: block;
    padding: 16px;
    text-align: center;
    width: 300px;
    background: var(--color-yellow);
    border-radius: 40px;
    font-family: "Montserrat-bd", sans-serif;
    color: var(--color-white);
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0px 0px 20px var(--color-yellow);
    }
  }
}

.head__desc {
  width: 410px;
  color: var(--color-white);

  @media (max-width: 680px) {
    width: 100%;
    font-size: 14px;
  }
}

.head__right {
  position: relative;
  height: 40px;

  div {
    &:nth-child(1) {
      position: absolute;

      right: 0;
      top: -420px;

      background: var(--color-red);
      width: 360px;
      padding: 10px 20px;
      border-radius: 0 68px 68px 68px;

      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: end;

      color: var(--color-white);

      h3 {
        font-size: 24px;
        font-family: "Montserrat-bd", sans-serif;
      }

      h2 {
        font-size: 32px;
        font-family: "Montserrat-bd", sans-serif;
      }

      @media (max-width: 495px) {
        display: none;
      }
    }

    &:nth-child(2) {
      position: absolute;

      right: 10%;
      top: 80px;

      background: var(--color-white);
      width: 360px;
      padding: 10px 20px;
      border-radius: 68px 68px 0px 68px;

      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: end;

      color: var(--color-black);

      h4 {
        font-size: 20px;
        font-family: "Montserrat-bd", sans-serif;
      }

      h5 {
        font-size: 16px;
        font-family: "Montserrat-bd", sans-serif;
        color: var(--color-yellow);
      }

      @media (max-width: 495px) {
        display: none;
      }
    }
  }
}
