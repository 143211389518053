.advantages {
  margin-top: 40px;
}

.advantages__wrapper {
  ul {
    display: flex;
    justify-content: space-between;

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      gap: 20px;

      width: 265px;
      height: 330px;
      border: 1px solid var(--color-black);
      border-radius: 10px;
      padding: 20px;

      img {
        width: 220px;
        height: 175px;
        object-fit: contain;
      }

      p {
        font-size: 20px;
        font-family: "Montserrat-bd", sans-serif;

        @media (max-width: 1120px) {
          font-size: 16px;
        }
      }

      @media (max-width: 1120px) {
        width: 100%;
        padding: 10px;
        gap: 15px;
      }
    }

    @media (max-width: 1120px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
}
