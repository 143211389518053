.golden_barrel {
  background: var(--color-red);
  padding-top: 100px;
  padding-bottom: 40px;

  @media (max-width: 1080px) {
    padding-bottom: 60px;
  }
}

.golden_barrel__wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 250px;

    background-image: url("../../assets/icons/arrow_3.svg");
    background-repeat: no-repeat;

    top: 35%;
    left: 73%;

    @media (max-width: 1120px) {
      display: none;
    }
  }

  h2 {
    font-size: 40px;
    font-family: "Montserrat-bd", sans-serif;
    font-weight: 700;
    color: var(--color-white);
    position: relative;

    span {
      color: var(--color-yellow);
      font-family: "Montserrat-eb", sans-serif;
      font-weight: 800;
    }

    width: 570px;

    &::before {
      content: "";
      position: absolute;
      width: 70px;
      height: 500px;

      background-image: url("../../assets/icons/arrow_1.svg");
      background-repeat: no-repeat;

      top: 100%;
      left: 15%;

      @media (max-width: 1120px) {
        display: none;
      }
    }

    @media (max-width: 660px) {
      width: 100%;
      font-size: 32px;
    }
  }

  .golden_barrel__barrel {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 470px;
      height: 507px;
      object-fit: contain;

      @media (max-width: 550px) {
        width: 100%;
      }
    }
  }
}

.golden_barrel__example {
  margin-left: 70%;

  @media (max-width: 1080px) {
    display: none;
  }
}

.golden_barrel__link {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  a {
    padding: 25px 115px;
    background: var(--color-dark-red);
    font-family: "Montserrat-bd", sans-serif;
    font-weight: 700;
    color: var(--color-white);
    border-radius: 20px;
    transition: all 0.2s ease;

    &:hover {
      opacity: 0.8;
    }

    @media (max-width: 380px) {
      padding: 25px 90px;
      margin-top: 20px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 110px;

    background-image: url("../../assets/icons/arrow_2.svg");
    background-repeat: no-repeat;

    top: -170%;
    left: 30%;

    @media (max-width: 1120px) {
      display: none;
    }
  }

  @media (max-width: 1080px) {
    margin-top: 270px;
  }
}

.golden_barrel__items {
  position: relative;
  height: 20px;

  @media (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .golden_barrel__left {
    position: absolute;
    top: -50%;
    background: var(--color-dark-red);
    border-radius: 20px;

    p {
      padding: 10px;
      width: 315px;
      color: var(--color-white);
      font-family: "Montserrat-bd", sans-serif;
      font-weight: 700;
      font-size: 20px;

      @media (max-width: 550px) {
        width: 100%;
      }

      @media (max-width: 360px) {
        font-size: 14px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 90px;
      height: 78px;
      background-image: url("../../assets/icons/golden_barrel_decor-left.svg");
      background-repeat: no-repeat;

      top: 45%;
      left: -4%;

      @media (max-width: 1080px) {
        display: none;
      }
    }

    &::after {
      content: "";
      position: absolute;
      width: 90px;
      height: 78px;
      background-image: url("../../assets/icons/golden_barrel_decor-right.svg");
      background-repeat: no-repeat;

      top: -10%;
      right: -4%;

      @media (max-width: 1080px) {
        display: none;
      }
    }

    @media (max-width: 1080px) {
      position: static;
      width: 90%;
      margin-top: 20px;
    }
  }

  .golden_barrel__right {
    position: absolute;
    top: -2500%;
    right: 0%;
    background: var(--color-dark-red);
    border-radius: 20px;

    p {
      padding: 10px;
      width: 335px;
      color: var(--color-white);
      font-family: "Montserrat-bd", sans-serif;
      font-weight: 700;
      font-size: 16px;

      @media (max-width: 550px) {
        width: 100%;
      }

      @media (max-width: 360px) {
        font-size: 14px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 90px;
      height: 78px;
      background-image: url("../../assets/icons/golden_barrel_decor-left.svg");
      background-repeat: no-repeat;

      top: 45%;
      left: -4%;

      @media (max-width: 1080px) {
        display: none;
      }
    }

    &::after {
      content: "";
      position: absolute;
      width: 90px;
      height: 78px;
      background-image: url("../../assets/icons/golden_barrel_decor-right.svg");
      background-repeat: no-repeat;

      top: -10%;
      right: -4%;

      @media (max-width: 1080px) {
        display: none;
      }
    }

    @media (max-width: 1080px) {
      position: static;
      width: 90%;
      margin-top: 20px;
    }
  }
}
